<script setup lang="ts">
import { unitLabelToSymbol } from '~/utils/unit';
import { isNutrientInInsufficiency, isNutrientPerfect } from '~/utils/analysis';
import { getPercent } from '~/utils/formatter';

const $api = useApi();
const { isLoading, setLoadingPending, setLoadingSuccess, setLoadingFailed } =
  useLoading();
const { closeModal } = useModalStore();

const props = defineProps({
  patient: {
    type: Object,
  },
  patientAge: Number,
  nutrient: {
    type: Object,
  },
  contributors: {
    type: Array<Record<string, string>>,
  },
  foods: {
    type: Array<Record<string, string>>,
  },
});

const SYNTHETIC_NUTRIENTS_EXPLANATIONS = [
  {
    nutrientLabel: 'omega3-EPA-DHA',
    explanation:
      'Les acides gras Oméga 3 EPA et DHA sont des acides gras polyinsaturés qui jouent un rôle important dans la santé cardiaque et cérébrale. EPA (acide eicosapentaénoïque) et DHA (acide docosahexaénoïque) ont des effets anti-inflammatoires et peuvent aider à réduire le risque de maladies cardiaques en régulant les niveaux de cholestérol et en abaissant la pression artérielle. Ils peuvent également améliorer la santé mentale en augmentant les niveaux de neurotransmetteurs comme la dopamine et la sérotonine.',
    inCaseOfDeficiency:
      "Un déficit en Oméga 3 est donc un facteur de risques dans les maladies à composante inflammatoire telles que les allergies, l’arthrose, la maladie de Crohn ou encore l’eczéma. C'est également le cas pour les maladies cardiaques (fibrillation artérielle, insuffisance cardiaque, troubles du rythme cardiaque, AVC…), ou pour des troubles mentaux (dépression, troubles de la mémoire, schizophrénie, démence) ou du comportement (hyperactivité). Enfin, des possibilités de problèmes cérébrovasculaires, comme la très célèbre maladie d’Alzheimer, sont un risque à ne pas écarter en cas de carence.",
    inCaseOfExcess: '',
  },
];

const nutrientExplanationData = ref();

const recommendation = computed(() => props.nutrient.recommendation);
const contributorsByEfficiency = computed(() =>
  props.nutrient?.contributors.sort(
    (a, b) =>
      b.nutrientQuantityForHundredGrams - a.nutrientQuantityForHundredGrams,
  ),
);
const recommendedContributors = computed(() =>
  contributorsByEfficiency.value
    .slice(0, 3)
    .reduce(
      (acc, c, index) => (acc += `${c.label}${index < 2 ? ', ' : ''}`),
      '',
    ),
);

const needToIncreaseSomeContributors = computed(
  () =>
    isNutrientInInsufficiency(props.nutrient) ||
    isNutrientInDeficiency(props.nutrient),
);
const needToDecreaseSomeContributors = computed(() =>
  isNutrientInExcess(props.nutrient),
);

function getFoodByLabel(label: string) {
  return props.foods.find((f) => f.label === label);
}

function getContributorCalories(food) {
  return Math.round(getFoodByLabel(food.label).totalCalories);
}

function getContributorQuantity(food) {
  return Math.round(getFoodByLabel(food.label).totalQuantity);
}
async function fetchNutrientData() {
  try {
    setLoadingPending();
    if (props.nutrient.synthetic) {
      nutrientExplanationData.value = SYNTHETIC_NUTRIENTS_EXPLANATIONS.find(
        (sE) => sE.nutrientLabel === props.nutrient.id,
      );
    } else {
      const { data: nutrient } = await $api.get(
        `/nutrient/${props.nutrient.nutrientId}`,
      );
      nutrientExplanationData.value = nutrient?.explanation;
    }

    setLoadingSuccess();
  } catch (e) {
    console.log(e);
    setLoadingFailed();
  }
}

fetchNutrientData();
</script>

<template>
  <BaseModal
    :title="`Détail de l'apport en ${props.nutrient.label}`"
    :width="900"
    :is-loading="isLoading"
  >
    <div class="flex flex-col gap-4">
      <div>
        <h3>
          Apport
          <BaseTag
            v-if="isNutrientPerfect(props.nutrient)"
            type="success"
            content="Parfait"
          />

          <BaseTag
            v-else-if="isNutrientInExcess(props.nutrient)"
            type="danger"
            content="En excès"
          />

          <BaseTag
            v-else-if="isNutrientInInsufficiency(props.nutrient)"
            type="warning"
            content="En insuffisance"
          />

          <BaseTag
            v-else-if="isNutrientInDeficiency(props.nutrient)"
            type="danger"
            content="En carence"
          />
        </h3>
        <hr />
        <h4>{{ props.nutrient.label }}</h4>
        <strong
          >Total = {{ props.nutrient.total.toFixed(2)
          }}{{ unitLabelToSymbol(props.nutrient.unit) }}</strong
        >
      </div>
      <div class="flex flex-col gap-6">
        <div v-if="recommendation">
          <h3>Recommandation</h3>
          <hr />

          <div
            v-if="
              needToIncreaseSomeContributors || needToDecreaseSomeContributors
            "
            class="mt-1"
          >
            <p>
              <BaseIcon
                :class="`text-${needToIncreaseSomeContributors ? 'green' : 'red'}-500`"
                :icon="`material-symbols:keyboard-double-arrow-${
                  needToIncreaseSomeContributors ? 'up' : 'down'
                }-rounded`"
              ></BaseIcon>
              {{ needToIncreaseSomeContributors ? 'augmenter' : 'réduire' }}
              l'apport en
              <BaseTag
                :content="recommendedContributors"
                :type="needToIncreaseSomeContributors ? 'success' : 'danger'"
              ></BaseTag>
              pour corriger
              {{
                needToIncreaseSomeContributors ? "l'insuffisance" : "l'excès"
              }}
              de ce nutriment.
            </p>
          </div>

          <p>
            {{
              $t(
                `recommendation.${recommendation.ageRange?.min && recommendation.ageRange?.max ? 'rangeAge' : 'fixedAge'}`,
                {
                  sexe: $t(`generic.gender.${props.patient.gender}`),
                  age: $t('generic.years', { years: patientAge }, patientAge),
                  minAge: $t(
                    'generic.years',
                    { years: recommendation.ageRange?.min },
                    recommendation.ageRange?.min,
                  ),
                  maxAge: $t(
                    'generic.years',
                    { years: recommendation.ageRange?.max },
                    recommendation.ageRange?.max,
                  ),
                },
              )
            }}
            <span v-if="recommendation.byKg">
              pesant {{ props.patient.weight_in_kg }} kg</span
            >
          </p>

          <div class="flex items-center gap-2">
            <p v-if="recommendation.values.min">
              <strong>Min</strong>: {{ recommendation.values.min
              }}{{ unitLabelToSymbol(props.nutrient.unit) }}
            </p>
            <p v-if="recommendation.values.max">
              <strong>Max</strong>: {{ recommendation.values.max
              }}{{ unitLabelToSymbol(props.nutrient.unit) }}
            </p>
          </div>
        </div>
        <div v-if="nutrientExplanationData?.explanation">
          <h3>Fonction</h3>
          <hr />
          <p>{{ nutrientExplanationData?.explanation }}</p>
        </div>
        <div
          v-if="
            needToIncreaseSomeContributors &&
            nutrientExplanationData?.inCaseOfDeficiency
          "
        >
          <h3>
            En cas de carence
            <BaseTag v-if="needToIncreaseSomeContributors" type="warning"
              >à lire</BaseTag
            >
          </h3>
          <hr />
          <p>{{ nutrientExplanationData?.inCaseOfDeficiency }}</p>
        </div>

        <div
          v-if="
            needToDecreaseSomeContributors &&
            nutrientExplanationData?.inCaseOfExcess
          "
        >
          <h3>
            En cas d'excès
            <BaseTag v-if="needToDecreaseSomeContributors" type="warning"
              >à lire</BaseTag
            >
          </h3>
          <hr />
          <p>{{ nutrientExplanationData?.inCaseOfExcess }}</p>
        </div>
        <div>
          <h3>Contributeurs</h3>
          <hr />
          <div class="flex flex-col items-center mt-2">
            <BaseDatagrid
              :sort="{ prop: 'rate', order: OrderDirection.ASC }"
              :columns="[
                {
                  label: 'Label',
                  key: 'label',
                  sortable: true,
                },
                {
                  label: 'Contribution',
                  key: 'rate',
                  sortable: true,
                  sortMethod: (a, b) =>
                    getPercent(b.quantity, props.nutrient.total) -
                    getPercent(a.quantity, props.nutrient.total),
                },
                {
                  label: 'Quantité ingérée',
                  key: 'quantity',
                  sortable: true,
                },
                {
                  label: 'Apport',
                  key: 'benefits',
                  sortable: true,
                  sortMethod: (a, b) => b.quantity - a.quantity,
                },
                {
                  label: 'Apport pour 100g',
                  key: 'ref',
                  sortable: true,
                },
              ]"
              :entries="props.contributors"
            >
              <template #row-data-label="{ row }">
                <strong>{{ row.label }}</strong>
              </template>
              <template #row-data-rate="{ row }">
                <el-progress
                  :stroke-width="20"
                  :percentage="getPercent(row.quantity, props.nutrient.total)"
                >
                  <span></span>
                </el-progress>
                <strong>
                  {{ getPercent(row.quantity, props.nutrient.total) }}%</strong
                >
              </template>

              <template #row-data-quantity="{ row }">
                {{ getContributorQuantity(row) }}g
                <span class="text-gray-500 text-[12px]"
                  >({{ getContributorCalories(row) }}kcal)</span
                >
              </template>

              <template #row-data-benefits="{ row }">
                <strong
                  >{{ row.quantity.toFixed(2) }}
                  {{ $t(`units.symbols.${props.nutrient.unit}`) }}</strong
                >
              </template>

              <template #row-data-ref="{ row }">
                <div class="flex items-end gap-2">
                  <BaseTag
                    type="success"
                    v-if="contributorsByEfficiency.slice(0, 3).includes(row)"
                    >Fort impact</BaseTag
                  >
                  {{ row.nutrientQuantityForHundredGrams }}
                  {{ $t(`units.symbols.${props.nutrient.unit}`) }}
                </div>
              </template>
            </BaseDatagrid>
          </div>
        </div>
      </div>
    </div>

    <template #footer>
      <BaseButton text="Fermer" @click="closeModal" />
    </template>
  </BaseModal>
</template>
